

@import "~bootstrap/scss/bootstrap";

.table{
  --bs-table-active-bg: #dcf5b2;
  --bs-table-striped-bg: #dcf5b2;
}

.btn-primary{
  background-color: #31f34b;
  border-color: #31f34b;
}

.btn-primary:hover{
  background-color: #31c444;
  border-color: #31c444;
}