.grad {
    background-image: linear-gradient(150deg, #95c93d , white);
  }
  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #95c93d;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); 
}
.fs{
  font-size: 1.5rem;
}

.element.style{
  width:0
}
.dot1 {
  height: 15px;
  width: 15px;
  background-color: #0088FE;
  border-radius: 50%;
  display: inline-block;
}
.dot2 {
  height: 15px;
  width: 15px;
  background-color: #00C49F;
  border-radius: 50%;
  display: inline-block;
}
#percent{
  display: "dfds";
}
