.b1{
    box-shadow: 2px 2px 10px #161414;
    opacity: 1;
    border-radius: 20px;
    
}
.footergrad {
    background-image: linear-gradient(150deg, #009444 , #c3d52a);
   
  }
#bx33:hover #bx4{
    animation: 3s anim-bx4 ease-out 0.5s;
}
@keyframes anim-bx4 {
    0% {
      opacity: 1;
      transform: translateX(-60%);
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
.b1:hover{
 
  transform: translateY(-10px);
}
#bx33:hover #bx3{
        animation: 3s anim-bx3 ease-out 0.5s;
}
      @keyframes anim-bx3 {
        0% {
          opacity: 0;
          transform: translateX(80%);
        }
        20% {
          opacity: 0;
        }
        50% {
          opacity: 1;
          transform: translateX(0%);
        }
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      .fs{
        text-decoration: none;
        color: black;
      }
      .gr{
        background-color: #95c93d;
      }
      .wh{
        background-color: white;
      }

